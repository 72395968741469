import { interpolateWarm } from 'd3-scale-chromatic';
import { isEmpty } from 'lodash';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { localPoint } from '@visx/event';
import { Group } from '@visx/group';
import { ParentSize } from '@visx/responsive';
import { Sankey, sankeyCenter } from '@visx/sankey';
import { scaleOrdinal } from '@visx/scale';
import { BarRounded, LinkHorizontal } from '@visx/shape';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';
import { Box } from '../../../shared/components';
import { getPageFlow } from '../../api/engagement';
import { useDashboard } from '../../context/DashboardContext';
import { mapPageFlowData } from '../../utils/engagement';
import { ChartContainer } from '../chart/ChartContainer';
import { ChartControlDropdown } from '../chart/ChartControlDropdown';

export const PageFlowChart = () => {
  // Routing
  const { businessId } = useParams();

  // Context
  const { range, GUIDE_OPTIONS } = useDashboard();

  // State
  const [guide, setGuide] = useState(GUIDE_OPTIONS[0]);

  // Queries
  const { data, isLoading } = useQuery(['pageFlow', businessId, guide, range], () => getPageFlow({ guide, range }));

  // Memos
  const pageFlowData = useMemo(() => {
    if (isLoading || !data?.Nodes || !data?.UserPathing) return {};
    return mapPageFlowData({ data });
  }, [data]);

  // Tooltip
  const { tooltipData, tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip } = useTooltip();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({ detectBounds: true, scroll: true });

  const colorScale = useMemo(() => {
    if (isEmpty(pageFlowData)) return scaleOrdinal();
    const pages = pageFlowData.nodes.map((node) => node.ID);
    return scaleOrdinal({
      domain: pages,
      range: pages.map((_, index) => interpolateWarm(0.8 - index / pages.length)),
    });
  }, [pageFlowData]);

  return (
    <ChartContainer
      isLoading={isLoading}
      isEmpty={isEmpty(pageFlowData)}
      title="Page Flow"
      description="Shows how visitors navigated through this guide (e.g. some users jumped from page 3 to page 5)."
      chartControls={
        <ChartControlDropdown label={guide.label} options={GUIDE_OPTIONS} onClick={(option) => setGuide(option)} />
      }
    >
      <Box
        css={`
          padding: 16px 0;
          height: 400px;
          width: 100%;
        `}
      >
        <ParentSize>
          {({ width, height }) => (
            <>
              <svg ref={containerRef} width={width} height={height}>
                <Sankey root={pageFlowData} nodeAlign={sankeyCenter} size={[width, height]}>
                  {({ graph, createPath }) => {
                    return (
                      <>
                        <Group>
                          {graph.links.map((link, i) => (
                            <LinkHorizontal
                              key={i}
                              data={link}
                              path={createPath}
                              fill="transparent"
                              stroke={colorScale(link.source.ID)}
                              strokeWidth={link.width}
                              strokeOpacity={0.5}
                              onPointerMove={(event) => {
                                const coords = localPoint(event.target.ownerSVGElement, event);
                                showTooltip({
                                  tooltipData: `${link.source.Title} > ${link.target.Title} = ${link.value}`,
                                  tooltipTop: (coords?.y ?? 0) + 10,
                                  tooltipLeft: (coords?.x ?? 0) + 10,
                                });
                              }}
                              onMouseOut={hideTooltip}
                            />
                          ))}
                        </Group>
                        <Group>
                          {graph.nodes.map(({ y0, y1, x0, x1, ID, Title }, i) => (
                            <BarRounded
                              key={i}
                              width={x1 - x0}
                              height={y1 - y0}
                              x={x0}
                              y={y0}
                              radius={3}
                              fill={colorScale(ID)}
                              all
                              onPointerMove={(event) => {
                                const coords = localPoint(event.target.ownerSVGElement, event);
                                showTooltip({
                                  tooltipData: Title,
                                  tooltipTop: (coords?.y ?? 0) + 10,
                                  tooltipLeft: (coords?.x ?? 0) + 10,
                                });
                              }}
                              onMouseOut={hideTooltip}
                            />
                          ))}
                        </Group>
                      </>
                    );
                  }}
                </Sankey>
              </svg>
              {tooltipOpen && (
                <TooltipInPortal key={Math.random()} top={tooltipTop} left={tooltipLeft}>
                  {tooltipData}
                </TooltipInPortal>
              )}
            </>
          )}
        </ParentSize>
      </Box>
    </ChartContainer>
  );
};
