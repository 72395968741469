export const DROP_CREATE_BLOCK = 'create-block';
export const DROP_MOVE_BLOCK = 'move-block';
export const DROP_MOVE_ROW = 'move-row';
export const DROP_MOVE_SECTION = 'move-section';
export const DROP_CREATE_SECTION = 'create-section';
export const TRANSPARENT_COLOR = 'rgba(255, 255, 255, 0)';

export const INPUT_DOM_ELEMENTS = ['INPUT', 'TEXTAREA'];

export const COLOR_MAP = {
  'accent-color-0': 'Accent Color 1',
  'accent-color-1': 'Accent Color 2',
  'accent-color-2': 'Accent Color 3',
  'accent-color-3': 'Accent Color 4',
  'accent-color-4': 'Accent Color 5',
  'background-color': 'Background Color',
  'button-background-color': 'Button Background Color',
  'button-text-color': 'Button Text Color',
  'text-body': 'Body',
  'text-h1': 'Heading 1',
  'text-h2': 'Heading 2',
  'text-h3': 'Heading 3',
  'text-h4': 'Heading 4',
  'text-subtitle': 'Subtitle',
};

export const TYPOGRAPHY_MAP = {
  h1: 'Heading 1',
  h2: 'Heading 2',
  h3: 'Heading 3',
  h4: 'Heading 4',
  body: 'Body',
  subtitle: 'Subtitle',
};

export const TOOL_TYPE_MAP = {
  section: 'Section',
  textV2: 'Text',
  button: 'Button',
  image: 'Image',
  space: 'Divider',
  line: 'Divider',
  document: 'Document',
  video: 'Video',
  website: 'Website',
  accordion: 'Accordion',
  benefits: 'Summary',
  pricing: 'Pricing',
  network: 'Network',
  pageBreak: 'Page Break',
};

export const CLICK_ACTIONS = {
  'open-website': {
    displayName: 'Open Website',
    tabType: 'new-tab',
    url: '',
  },
  'route-to-page': {
    displayName: 'Route To Page',
    tabType: 'same-tab',
    pageId: '',
  },
  'open-email': {
    displayName: 'Send Email',
    sendToEmail: '',
    subject: '',
    body: '',
  },
  'open-sms': {
    displayName: 'Send SMS',
    sendToNumber: '',
    message: '',
  },
  'call-phone': {
    displayName: 'Call Phone',
    callNumber: '',
  },
};

export const TAB_MAP = {
  'new-tab': 'New Tab',
  'same-tab': 'Same Tab',
};

export const SIDEBAR_TABS = {
  TOOLS: 'tools',
  COMMENTS: 'comment',
  CONNECTIONS: 'connections',
  VERSION_HISTORY: 'version-history',
};
