import { animation } from '../../shared/shared-styles';
import { colors } from '../../shared/styles';
import { overlay } from '../utility/styles';

export const getStyles = (root, properties, viewMode) => {
  const { backgroundColor = '', ...styles } = properties?.style || {};
  const isMobile = viewMode === 'mobile';
  const isFullScreen = properties?.fullScreenWidth;

  const width = isMobile ? { width: '350px' } : {};

  const bgWidth = isFullScreen
    ? { width: '100%', maxWidth: '100%', minWidth: '100%' }
    : {
        width: '100%',
        ...width,
        maxWidth: root?.style?.maxWidth,
        minWidth: root?.style?.minWidth,
      };

  const background = {
    backgroundColor: backgroundColor || 'rgba(0, 0, 0, 0)',
    ...bgWidth,
    ...(styles || {}),
  };

  return {
    container: isFullScreen ? styles : {},
    section: { ...root?.style, ...(!isFullScreen ? styles : {}), ...width },
    background,
  };
};

const emptySection = (props) =>
  props?.childCount === 0
    ? `
  ${animation('grow', '.3s ease')}
  background-color: ${colors.gray[100]};
  border-top: 1px dashed ${colors.gray[200]};
  border-bottom: 1px dashed ${colors.gray[200]};
`
    : '';

const selectedSection = (props) =>
  props?.isSelected
    ? `
  ::after {
    ${overlay}
    outline: 2px solid ${colors.purple};
    outline-offset: -2px;
  }
`
    : '';

const dropSection = (props) =>
  props?.isDropEnabled
    ? `
  border-bottom: 1px dashed rgba(150, 150, 150, .8);
  margin-bottom: -1px;
`
    : '';

const devModeStyle = (props) =>
  props?.devMode
    ? `
  ::after {
    ${overlay}
    outline: 1px dashed ${colors.purple};
    outline-offset: -1px;
  }
`
    : '';

const dragSource = (props) =>
  props?.isDraggingSource
    ? `
  outline: 2px solid ${colors.purple};
  filter: brightness(50%) saturate(0);
`
    : '';

const hoverSection = (props) => {
  const canHover = !props?.isSelected && props?.showSections && props?.childCount > 0 && !props?.readOnly;

  return canHover
    ? `
    ::after {
      ${overlay}
      outline: 1px solid rgba(255, 255, 255, .5);
      outline-offset: -3px;
      border: 2px solid color-mix(in srgb, ${colors.purple} 80%, transparent);
      pointer-events: none;
    }
  `
    : '';
};

const directHover = (props) => {
  const canHover = !props?.isSelected && props?.showSections && props?.childCount > 0;
  return canHover
    ? `
    outline: 1px solid rgba(255, 255, 255, .5);
    outline-offset: -3px;
    border: 2px solid color-mix(in srgb, ${colors.purple} 80%, transparent);
    background-color: rgba(150, 150, 200, .1);
  `
    : '';
};

export const sectionCss = (props) => `
  position: relative;
  ${emptySection(props)}
  ${selectedSection(props)}
  ${dropSection(props)}
  ${devModeStyle(props)}
  ${dragSource(props)}
    
  width: 100%;
  transition: filter .2s ease, height .2s ease;
  
  .hover-settings {
    position: absolute;
    top: 0;
    right: 0;
    padding: 2px;
  }
    
  :not(:hover) {
    .hover-settings {
      display: none;
    }
  }
  :hover {
    ${hoverSection(props)}
    :not(:has(*:hover)) {
      ::after {
        ${overlay}
        pointer-events: none;
        transition: background-color .4s ease;
        ${directHover(props)}
      }
    }
  }
  transition: width .2s ease, border-radius .2s ease, background-color .2s ease, padding .2s ease;
  .section {
    ${props?.section?.properies?.gap ? `gap: ${props?.section?.properies?.gap};` : ''}
    transition: width .2s ease, border-radius .2s ease, background-color .2s ease, padding .2s ease;
  }
  .section-styles {
    ${props?.section?.properies?.gap ? `gap: ${props?.section?.properies?.gap};` : ''}
  }
  .section-background {
    transition: max-width .2s ease, min-width .2s ease, width .2s ease, background-color .2s ease;
  }

`;
