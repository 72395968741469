import { memo, useEffect, useMemo, useRef } from 'react';
import { Box, Text } from '../../shared/components';
import { colors } from '../../shared/styles';
import { SpaceBlock } from '../blocks/space-block/SpaceBlock';
import { Segment } from '../providers/segment/Segment';
import { useSegment } from '../providers/segment/use-segment';
import { useToolKit } from '../providers/tool-kit/use-tool-kit';
import { overlay } from '../utility/styles';
import { ToolKit } from '../providers/tool-kit/ToolKit';
import { Droppable } from '../utility/Droppable';
import { useDroppable } from '../utility/useDroppable';
import { blockContainerDefaultStyle, blockDefaultStyle, getCanDragBlock, getDroppableProps } from './block-helpers';
import { DROP_MOVE_BLOCK, INPUT_DOM_ELEMENTS, SIDEBAR_TABS } from '../utility/constants';
import { WebsiteBlock } from '../blocks/website-block/WebsiteBlock';
import { DocumentBlock } from '../blocks/document-block/DocumentBlock';
import { VideoBlock } from '../blocks/video-block/VideoBlock';
import { ImageBlock } from '../blocks/image-block/ImageBlock';
import { ButtonBlock } from '../blocks/button-block/ButtonBlock';
import { TextBlock } from '../blocks/text-block/TextBlock';
import { NetworkBlock } from '../blocks/smart-components/network-block/NetworkBlock';
import { AccordionBlock } from '../blocks/accordion-block/AccordionBlock';
import { SummaryBlock } from '../blocks/smart-components/summary-block/SummaryBlock';
import { PricingBlock } from '../blocks/smart-components/pricing-block/PricingBlock';
import { useEditorResource } from '../../BriteEditor/use-editor-resource';
import { getThreadByBlock } from '../utility/editor-utils';
import { ChatCircle, ClockClockwise } from '@phosphor-icons/react';
import { Tooltip } from '../../common/components/Tooltip';
import { animation } from '../../shared/shared-styles';
import { PageBreakBlock } from '../blocks/page-break-block/PageBreak';

const blocks = {
  space: SpaceBlock,
  line: SpaceBlock,
  website: WebsiteBlock,
  document: DocumentBlock,
  video: VideoBlock,
  image: ImageBlock,
  button: ButtonBlock,
  textV2: TextBlock,
  accordion: AccordionBlock,
  network: NetworkBlock,
  benefits: SummaryBlock,
  pricing: PricingBlock,
  pageBreak: PageBreakBlock,
};

export const Block = memo(() => {
  const blockRef = useRef();
  const toolkit = useToolKit();
  const segment = useSegment();

  const comments = useEditorResource('comments');

  const isSelected = Segment.getIsSelected(segment, toolkit);
  const isHighlighted = Segment.getIsHighlighted(segment, toolkit);
  const style = Segment.getContainerStyle(segment);

  const { commentsByBlock, selectedComment, scrollTo, currentTab, users, versionChanges } = useMemo(() => {
    const { type: currentTab } = ToolKit.getCurrentTab(toolkit);
    const commentsByBlock = ToolKit.getCommentByBlockId(toolkit, segment?.id);
    const selectedComment = ToolKit.getComment(toolkit);
    const scrollTo = ToolKit.getScrollTo(toolkit);
    const liveUsers = ToolKit.getUsers(toolkit);
    const versionChanges = ToolKit.getVersionChangesByComponent(toolkit, segment?.id);
    const users = Object.values(liveUsers).filter(({ blockId }) => blockId === segment?.id);
    return {
      commentsByBlock,
      selectedComment,
      scrollTo,
      currentTab,
      users,
      versionChanges,
    };
  }, [toolkit, segment?.id]);

  useEffect(() => {
    if (
      (selectedComment?.source === 'comment' || selectedComment?.source === 'query-param') &&
      selectedComment?.blockId === segment?.id
    ) {
      blockRef?.current?.scrollIntoView({ behavior: 'instant', block: 'center' });
    }
  }, [selectedComment?.blockId]);

  useEffect(() => {
    if (scrollTo?.id === segment?.id) {
      blockRef?.current?.scrollIntoView({ behavior: 'instant', block: 'center' });
    }
  }, [scrollTo?.updatedAt]);

  const doubleClick = () => ToolKit.setDisplaySettings(toolkit, true);

  const openContextMenu = (event) => {
    const isInput = INPUT_DOM_ELEMENTS.includes(event.target.tagName);
    if (!isInput) {
      event.preventDefault();
      const editor = Segment.getElement(segment, 'editor');
      ToolKit.openContextMenu(toolkit, {
        event,
        editor,
        context: {
          toolType: segment?.type,
          id: segment?.id,
          scope: 'block',
        },
      });
    }
  };

  const multiselect = () => {
    ToolKit.multiSelect(toolkit, {
      id: segment?.id,
      type: segment?.type,
      element: blockRef.current,
      scope: 'block',
      strategy: 'shift',
    });
  };

  const singleSelect = () => {
    ToolKit.select(toolkit, {
      id: segment?.id,
      type: segment?.type,
      scope: 'block',
    });
  };

  const selectComment = () => {
    if (commentsByBlock?.length) {
      const thread = getThreadByBlock(commentsByBlock, comments?.data);
      ToolKit.selectComment(toolkit, {
        source: 'comment',
        threadId: thread?.id,
        blockId: thread?.metadata?.componentId,
      });
    } else {
      ToolKit.initComment(toolkit, segment?.id);
    }
  };

  const selectBlock = (event) => {
    const shift = event.getModifierState('Shift');
    if (shift) {
      multiselect();
    } else if (!shift) {
      singleSelect();
      if (currentTab === SIDEBAR_TABS.COMMENTS) {
        selectComment();
      }
    }
  };

  const drag = ToolKit.getDrag(toolkit);

  const handleDragStart = (event) => {
    event.preventDefault();
    event.stopPropagation();
    ToolKit.startDrag(toolkit, {
      toolType: segment?.type,
      sourceId: segment.id,
      type: DROP_MOVE_BLOCK,
    });
  };

  const drop = useMemo(() => getDroppableProps(segment, drag), [segment, drag?.isDragging]);
  const droppable = useDroppable(drop.props);
  const canDrag = getCanDragBlock({ isSelected, segment, drag });

  const BlockComponent = blocks[segment?.type];

  const { margin = '0px', ...blockStyle } = style;

  return (
    <Box
      css={blockContainerDefaultStyle({
        isHighlighted,
        isBeingDragged: drop?.isBeingDragged,
        isDragAvailable: drag?.isAvailable,
      })}
      data-scope="block"
      data-id={segment.id}
      style={{ padding: margin }}
    >
      <Box
        {...droppable.attributes}
        ref={blockRef}
        style={blockStyle}
        css={blockDefaultStyle(isSelected)}
        className="block-wrapper"
        draggable={canDrag}
        onDragStart={handleDragStart}
        onClick={selectBlock}
        onContextMenu={openContextMenu}
        onDoubleClick={doubleClick}
      >
        {segment?.type in blocks ? (
          <BlockComponent />
        ) : (
          <Box
            flex="left"
            css={`
              border: 4px dotted ${colors.yellow[100]};
              border-radius: 8px;
              padding: 8px 16px;
            `}
          >
            <Text>{segment?.type}</Text>
          </Box>
        )}
      </Box>
      <BlockOverlay
        users={users}
        isHighlighted={isHighlighted}
        versionChanges={versionChanges}
        commentCount={currentTab === SIDEBAR_TABS.COMMENTS ? commentsByBlock?.length : 0}
      />
      <Droppable {...droppable} />
      {ToolKit.isStatus(toolkit, ToolKit.STATUS.READ_ONLY) ? (
        <ReadOnlyBlock selectComment={selectComment} blockRef={blockRef} />
      ) : null}
    </Box>
  );
});

const ReadOnlyBlock = ({ selectComment }) => {
  return (
    <Box
      css={`
        ${overlay}
        pointer-events: auto !important;
      `}
      onClick={selectComment}
    />
  );
};

const overlayStyles = ({ isHighlighted, users, versionChanges }) => `
  pointer-events: ${users?.length ? 'auto' : 'none'};
  border-radius: 1px;
  ${
    isHighlighted
      ? `
        outline: 1px solid rgba(255, 255, 255, .5);
        border: 2px solid ${colors.purple}AA;
        margin: -2px;
      `
      : ''
  }
  ${overlay}
  ${
    users?.length || versionChanges?.length
      ? `
    ::after {
      ${overlay}
      content: '';
      outline: 2px solid ${
        !versionChanges?.length
          ? users?.length === 1
            ? users?.[0]?.data?.Preferences?.Color || colors.purple
            : colors.purple
          : colors.lite.peel
      };
      ${
        versionChanges?.length
          ? `
          background-color: ${colors.lite.peel}12;
      `
          : ''
      }
      outline-offset: -4px;
    }
  `
      : ''
  }
`;

const BlockOverlay = ({ isHighlighted, commentCount, users, versionChanges }) => {
  const getDisplayLabel = (user) => user?.FirstName || user?.LastName || user?.Email;
  return (
    <Box css={overlayStyles({ isHighlighted, users, versionChanges })} className="overlay">
      <Box
        css={`
          position: absolute;
          bottom: calc(100% - 4px);
          left: 0;
          width: 100%;
        `}
      >
        {users?.map((item) => (
          <Text
            helper
            bold
            css={`
              color: white;
              background-color: ${item?.data?.Preferences?.Color || colors.purple};
              padding: 0 4px;
              border-radius: 8px;
              width: max-content;
            `}
          >
            {getDisplayLabel(item?.data)}
          </Text>
        ))}
      </Box>
      {commentCount ? (
        <Tooltip label={`${commentCount} Comments`}>
          <Box
            flex="center"
            css={`
              position: absolute;
              top: 8px;
              right: 8px;
              border-radius: 50%;
              cursor: pointer;
              z-index: 100;
              background-color: ${colors.purple};
              border-radius: 30px;
              min-width: 24px;
              height: 24px;
              ${animation('gelatine', '.3s ease')}
            `}
          >
            <ChatCircle size={16} color="white" weight="fill" />
          </Box>
        </Tooltip>
      ) : null}
    </Box>
  );
};
