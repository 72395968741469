import { useParams } from 'react-router';
import { CustomAxios } from '../../redux/axios/axios';
import { Box, BriteLoader, Button, Text } from '../../shared/components';
import { container, scrollbar } from '../../shared/shared-styles';
import { useQuery } from 'react-query';
import { useSearchParams } from '../../shared/use-search-params';
import { format } from 'date-fns';
import { colors } from '../../shared/styles';
import { useEffect, useRef, useState } from 'react';
import { Collapse } from '../../common/components/Collapse';
import { ArrowClockwise, CheckCircle } from '@phosphor-icons/react';
import { useContent } from '../providers/content/use-content';
import { useToolKit } from '../providers/tool-kit/use-tool-kit';
import { ToolKit } from '../providers/tool-kit/ToolKit';
import { Content } from '../providers/content/Content';

const getVersionHistory = (params) => async () => {
  const { data } = await CustomAxios.get(
    `/v2/course/${params?.guideId}/pages/${params?.pageId}/versionsv2?timeBucket=${params?.timebucket}`
  );
  return data;
};

const getChangesHistory = (params) => async () => {
  const { data } = await CustomAxios.get(
    `/v2/course/${params?.guideId}/pages/${params?.pageId}/versionsv2/applyVersion?targetChange=${params?.targetChangeId}`
  );
  return data;
};

const cardStyle = (selected) => `
  display: grid;
  gap: 8px;
  border-radius: 8px;
  padding: 8px;
  padding-bottom: 0;
  cursor: pointer;
  transition: padding .2s ease;
  box-sizing: border-box;
  ${
    selected
      ? `
        border: 1px solid transparent;
        outline: 2px solid ${colors.purple};
        padding: 16px 8px;
      `
      : `border: 1px solid ${colors.gray[300]}; ${container.hoverOutline}`
  }
`;

export const VersionHistory = () => {
  const toolkit = useToolKit();
  const { sendUpdates, content, setContent } = useContent({ ignoreSelection: true });
  const oldContent = useRef(structuredClone(content));
  const params = useParams();

  const {
    params: { pageId },
  } = useSearchParams();

  const [restoring, setRestoring] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState();

  const versionHistory = useQuery(
    [params?.businessId, 'version-history', pageId],
    getVersionHistory({
      pageId,
      timebucket: 900,
      guideId: params.courseId,
    }),
    {
      enabled: !!pageId && !!params?.courseId,
      staleTime: Infinity,
    }
  );

  const versionContentIsEnabled = !!pageId && !!params?.courseId && !!selectedVersion?.LastChangeID;
  const versionContent = useQuery(
    [params?.businessId, 'version-content', pageId, selectedVersion?.LastChangeID],
    getChangesHistory({
      pageId,
      targetChangeId: selectedVersion?.LastChangeID,
      guideId: params.courseId,
    }),
    {
      enabled: versionContentIsEnabled,
    }
  );

  const handleVersionUpdate = () => {
    setContent(versionContent?.data);
    const id = selectedVersion?.AllChanges?.[0]?.id;
    ToolKit.scrollTo(toolkit, id);
    const changes = selectedVersion?.AllChanges?.reduce(
      (prev, item) => ({
        ...prev,
        [item?.id]: [...(prev?.[item?.id] || []), item],
      }),
      {}
    );
    ToolKit.setVersionChanges(toolkit, changes);
  };

  useEffect(() => {
    if (!versionHistory?.isFetching) {
      setRestoring(false);
    }
  }, [versionHistory?.isFetching]);

  useEffect(() => {
    if (versionContentIsEnabled && versionContent?.data) {
      handleVersionUpdate();
    }
  }, [versionContent?.dataUpdatedAt]);

  useEffect(() => {
    ToolKit.setIsVersionChangesLoading(toolkit, versionContent?.isFetching);
  }, [versionContent?.isFetching]);

  useEffect(() => {
    if (selectedVersion === null) {
      setContent(oldContent.current);
    }
    ToolKit.resetVersionChanges(toolkit);
  }, [selectedVersion]);

  useEffect(() => {
    return () => {
      setContent(oldContent.current);
      ToolKit.resetVersionChanges(toolkit);
    };
  }, []);

  const selectVersion = (version, idx) => {
    if (idx === 0) {
      setSelectedVersion(null);
    } else {
      const selection = selectedVersion?.LastChangeID === version?.LastChangeID ? '' : version;
      setSelectedVersion(selection);
    }
  };

  const restoreVersion = async (event) => {
    setRestoring(true);
    event?.stopPropagation();
    sendUpdates((current) => {
      const pageAction = Content.getPageAction(oldContent.current, current);
      const actions = [pageAction];
      const content = Content.commit(current, actions);
      oldContent.current = structuredClone(content);
      return { content, actions };
    });
    setSelectedVersion(null);
    setTimeout(() => {
      versionHistory?.refetch();
    }, 1000);
  };

  return (
    <Box
      flex="column"
      css={`
        position: relative;
        width: 300px;
        height: 100%;
        padding: 16px;
        gap: 8px;
        overflow-x: clip;
        background-color: white;
        overflow-y: auto;
        ${scrollbar.hide}
      `}
    >
      <Text label bold>
        Version History
      </Text>
      {versionHistory?.isFetching || restoring ? (
        <BriteLoader size={48} weight={8} />
      ) : (
        versionHistory?.data?.map((item, idx) => (
          <Box
            key={item?.LastChangeID}
            css={cardStyle(selectedVersion ? item?.LastChangeID === selectedVersion?.LastChangeID : idx === 0)}
            onClick={() => selectVersion(item, idx)}
          >
            <Box
              css={`
                display: grid;
                gap: 8px;
                p {
                  color: ${colors.black};
                }
              `}
            >
              <Box flex="space-between">
                <Text caption bold>
                  {format(item?.LastChangeTime, 'MMMM d, h:mma')}
                </Text>
                {selectedVersion && selectedVersion?.LastChangeID === item?.LastChangeID ? (
                  versionContent?.isFetching ? (
                    <BriteLoader overlay={false} size={20} weight={12} />
                  ) : (
                    <CheckCircle size={20} color={colors.purple} weight="fill" />
                  )
                ) : idx === 0 ? (
                  <Text
                    caption
                    bold
                    css={`
                      border-radius: 30px;
                      background-color: ${colors.green[300]};
                      border: 1px solid ${colors.green[100]};
                      padding: 0 8px;
                      margin: 0 -2px;
                      max-width: max-content;
                    `}
                  >
                    Current
                  </Text>
                ) : null}
              </Box>
            </Box>
            <Collapse isOpen={selectedVersion && selectedVersion?.LastChangeID === item?.LastChangeID}>
              <Box>
                <Button
                  css={`
                    width: 100%;
                    text-transform: none;
                  `}
                  disabled={versionContent?.isFetching}
                  onClick={restoreVersion}
                >
                  <ArrowClockwise size={16} color="white" />
                  Restore
                </Button>
              </Box>
            </Collapse>
          </Box>
        ))
      )}
    </Box>
  );
};
