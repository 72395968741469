import { useFeatureFlagPayload } from 'posthog-js/react';
import { useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Tabs } from '../shared/components';
import { EngagementTab } from './components/engagement/EngagementTab';
import { TrafficTab } from './components/traffic/TrafficTab';
import { DashboardProvider } from './context/DashboardContext';
import { ENGAGEMENT_TAB, TRAFFIC_TAB } from './utils/dashboard';
import { DashboardHeader } from './DashboardHeader';

export const DashboardPage = () => {
  // Routing
  const history = useHistory();
  const { businessId, id, tab } = useParams();

  // Feature Flags
  const wipFF = useFeatureFlagPayload('wip-aggregated-guide-analytics');

  // Memos
  const tabs = useMemo(
    () => (wipFF?.value ? { [TRAFFIC_TAB]: 'Traffic', [ENGAGEMENT_TAB]: 'Engagement' } : { [TRAFFIC_TAB]: 'Traffic' }),
    [wipFF]
  );

  const goToTab = (tab) => {
    history.push(`/${businessId}/analytics/dashboards/${id}/${tab}`);
  };

  return (
    <DashboardProvider {...{ businessId, id }}>
      <Box
        css={`
          display: flex;
          flex-direction: column;
        `}
      >
        <Box
          css={`
            background-color: #ffffff;
            position: sticky;
            top: 112px;
            z-index: 10;
          `}
        >
          <DashboardHeader />
          <Tabs tabs={tabs} current={tab} setCurrent={(tab) => goToTab(tab)} />
        </Box>
        <Box
          css={`
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 36px 0;
          `}
        >
          <Box
            page
            css={`
              display: flex;
              flex-direction: column;
              gap: 36px;
            `}
          >
            {tab === TRAFFIC_TAB && <TrafficTab />}
            {tab === ENGAGEMENT_TAB && <EngagementTab />}
          </Box>
        </Box>
      </Box>
    </DashboardProvider>
  );
};
