import { createContext, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { Editor } from '../../Editor';
import { toolKitReducer, initialToolkit } from './tool-kit-reducer';
import { safelySpread } from '../content/content-modify';
import { getMultiselect } from '../../utility/multi-select-utils';
import { Content } from '../content/Content';
import { useAcls } from '../../../shared/use-acls';
import { WRITE_COURSE } from '../../../shared/acl-constants';
import { SIDEBAR_TABS } from '../../utility/constants';

export const ToolKitContext = createContext();

export const ToolKit = (props) => {
  const canWrite = useAcls([WRITE_COURSE]);
  const [status, setStatus] = useState(ToolKit.STATUS.READ_ONLY);

  const cache = useRef({});
  const [data, dispatch] = useReducer(toolKitReducer, initialToolkit);

  useEffect(() => {
    setStatus(canWrite ? ToolKit.STATUS.ADMIN : ToolKit.STATUS.READ_ONLY);
    if (!canWrite) {
      dispatch({
        type: 'UPDATE_PROPERTY',
        property: 'currentTab',
        payload: { type: SIDEBAR_TABS.COMMENTS },
      });
    }
  }, [canWrite]);

  useEffect(() => {
    if (data?.currentTab?.type === SIDEBAR_TABS.VERSION_HISTORY && status !== ToolKit.STATUS.READ_ONLY_CONTENT) {
      setStatus(ToolKit.STATUS.READ_ONLY_CONTENT);
    } else if (canWrite && status === ToolKit.STATUS.READ_ONLY_CONTENT) {
      setStatus(ToolKit.STATUS.ADMIN);
    }
  }, [data?.currentTab?.type]);

  const metadata = useMemo(() => {
    return {
      guide: props?.course,
      page: {
        data: props?.query?.data,
      },
    };
  }, [props?.course?.updatedAt, props?.query?.dataUpdatedAt]);

  return (
    <ToolKitContext.Provider
      value={{
        status,
        setStatus,
        cache,
        data,
        dispatch,
        metadata,
      }}
    >
      <Content page={props?.query} savePage={props?.savePage}>
        <Editor {...props} />
      </Content>
    </ToolKitContext.Provider>
  );
};

ToolKit.getCommentByBlockId = (toolkit, blockId) => toolkit?.data?.commentsByBlock?.[blockId] || [];
ToolKit.setCommentsByBlock = (toolkit, commentsByBlock) => {
  toolkit.dispatch({
    type: 'SET_PROPERTY',
    property: 'commentsByBlock',
    payload: commentsByBlock,
  });
};
ToolKit.setCurrentTab = (toolkit, type, options = initialToolkit?.currentTab?.options) => {
  if (toolkit.status !== ToolKit.STATUS.READ_ONLY || type === SIDEBAR_TABS.COMMENTS || !type) {
    toolkit.dispatch({
      type: 'SET_PROPERTY',
      property: 'currentTab',
      payload: { type, options },
    });
  }
};
ToolKit.getCurrentTab = (toolkit) => toolkit?.data?.currentTab;
ToolKit.getEditorType = (toolkit) => toolkit?.data?.editorType;
ToolKit.getModal = (toolkit) => toolkit?.data?.modal?.type || '';
ToolKit.getModalData = (toolkit) => toolkit?.data?.modal?.data || {};
ToolKit.setModal = (toolkit, payload) => {
  toolkit.dispatch({
    type: 'SET_PROPERTY',
    property: 'modal',
    payload,
  });
};
ToolKit.resetModal = (toolkit) => {
  toolkit.dispatch({
    type: 'RESET_PROPERTY',
    property: 'modal',
  });
};
ToolKit.hasSelection = (toolkit) => !!toolkit?.data?.selection?.range?.anchor;
ToolKit.hasSingleSelection = (toolkit) =>
  !!toolkit?.data?.selection?.range?.anchor && !toolkit?.data?.selection?.range?.focus;
ToolKit.hasMultiSelection = (toolkit) =>
  !!toolkit?.data?.selection?.range?.anchor && !!toolkit?.data?.selection?.range?.focus;

ToolKit.selectionHasGroupedSection = (toolkit, content, id) => {
  const hasMultiSelect = ToolKit.hasMultiSelection(toolkit);
  return (
    hasMultiSelect &&
    !toolkit?.data?.selection?.multiSelect?.sections?.some((item) => item?.isGrouped || item?.children?.length > 1)
  );
};

ToolKit.getSelection = (toolkit) => {
  const selection = toolkit?.data?.selection || {};
  return {
    ...selection,
    id: selection?.range?.anchor,
  };
};

ToolKit.getMultiSelection = (toolkit) => toolkit?.data?.selection || {};

ToolKit.multiSelect = (toolkit, { strategy, id }) => {
  const selection = ToolKit.getSelection(toolkit);
  const payload = getMultiselect(selection, strategy, id);
  toolkit.dispatch({
    type: 'SET_PROPERTY',
    property: 'selection',
    payload,
  });
};

ToolKit.setMultiSelect = (toolkit, multiSelect) => {
  toolkit.dispatch({
    type: 'UPDATE_PROPERTY',
    property: 'selection',
    payload: {
      multiSelect,
    },
  });
};

ToolKit.select = (toolkit, { id, type, scope }) => {
  toolkit.dispatch({
    type: 'SET_PROPERTY',
    property: 'selection',
    payload: {
      scope,
      type,
      at: Date.now(),
      range: {
        anchor: id,
        focus: '',
      },
    },
  });
};

ToolKit.deselect = (toolkit) => {
  toolkit.dispatch({
    type: 'RESET_PROPERTY',
    property: 'selection',
  });
};

ToolKit.getDrag = (toolkit) => toolkit?.data?.drag || {};

ToolKit.disableDrag = (toolkit) => {
  toolkit.dispatch({
    type: 'UPDATE_PROPERTY',
    property: 'drag',
    payload: {
      isAvailable: false,
    },
  });
};

ToolKit.startDrag = (toolkit, dragData) => {
  toolkit.dispatch({
    type: 'RESET_PROPERTY',
    property: 'selection',
  });
  toolkit.dispatch({
    type: 'SET_PROPERTY',
    property: 'drag',
    payload: {
      ...initialToolkit?.drag,
      ...dragData,
      isDragging: true,
    },
  });
};

ToolKit.drop = (toolkit, dropData) => {
  toolkit.dispatch({
    type: 'UPDATE_PROPERTY',
    property: 'drag',
    payload: {
      completedAt: Date.now(),
      isComplete: true,
      isDragging: false,
      ...dropData,
    },
  });
};

ToolKit.resetDrag = (toolkit) => {
  toolkit.dispatch({
    type: 'RESET_PROPERTY',
    property: 'drag',
  });
};

ToolKit.openContextMenu = (toolkit, props) => {
  const rect = props.editor.getBoundingClientRect();
  const { clientX, clientY } = props.event;
  const x = clientX - rect.left;
  const y = clientY - rect.top;
  const position = { x, y };

  toolkit.dispatch({
    type: 'SET_PROPERTY',
    property: 'contextMenu',
    payload: {
      position,
      display: true,
      updatedAt: Date.now(),
      context: props.context,
    },
  });
};

ToolKit.getContextMenu = (toolkit) => toolkit?.data?.contextMenu || {};
ToolKit.closeContextMenu = (toolkit) => {
  toolkit.dispatch({
    type: 'RESET_PROPERTY',
    property: 'contextMenu',
  });
};

ToolKit.getMetadata = (toolkit, property) => toolkit?.metadata?.[property];

ToolKit.getViewMode = (toolkit) => toolkit?.data?.viewMode;
ToolKit.setViewMode = (toolkit, viewMode) => {
  toolkit.dispatch({
    type: 'SET_PROPERTY',
    property: 'viewMode',
    payload: viewMode,
  });
};

ToolKit.mergeCache = (toolkit, property, value) => {
  toolkit.cache.current[property] = {
    ...safelySpread(toolkit?.cache?.current?.[property] || {}),
    ...value,
  };
};

ToolKit.getCache = (toolkit, key) => toolkit?.cache?.current?.[key] || {};
ToolKit.removeCache = (toolkit, key) => {
  try {
    delete toolkit.cache.current[key];
  } catch (err) {
    console.warn(err);
  }
};

ToolKit.setKeyboard = (toolkit, keyboard) => {
  toolkit.dispatch({
    type: 'UPDATE_PROPERTY',
    property: 'keyboard',
    payload: {
      [keyboard.type]: {
        updatedAt: Date.now(),
        ...keyboard,
      },
    },
  });
};
ToolKit.setDisplaySettings = (toolkit, payload) => {
  toolkit.dispatch({
    type: 'SET_PROPERTY',
    property: 'settings',
    payload,
  });
};

ToolKit.getIsDisplaySettings = (toolkit) => toolkit?.data?.settings;

ToolKit.initComment = (toolkit, blockId) => {
  toolkit.dispatch({
    type: 'SET_PROPERTY',
    property: 'comment',
    payload: {
      source: 'initial',
      blockId,
      threadId: '',
    },
  });
};

ToolKit.selectComment = (toolkit, payload) => {
  toolkit.dispatch({
    type: 'UPDATE_PROPERTY',
    property: 'comment',
    payload,
  });
};

ToolKit.getComment = (toolkit) => toolkit?.data?.comment;

ToolKit.resetComment = (toolkit) => {
  toolkit.dispatch({
    type: 'RESET_PROPERTY',
    property: 'comment',
  });
};

ToolKit.setIsVersionChangesLoading = (toolkit, isLoading) => {
  toolkit.dispatch({
    type: 'SET_PROPERTY',
    property: 'isVersionChangesLoading',
    payload: isLoading,
  });
};
ToolKit.getIsVersionChangesLoading = (toolkit) => toolkit?.data?.isVersionChangesLoading;

ToolKit.setVersionChanges = (toolkit, payload) => {
  toolkit.dispatch({
    type: 'SET_PROPERTY',
    property: 'changesByComponent',
    payload,
  });
};

ToolKit.getVersionChanges = (toolkit) => toolkit?.data?.changesByComponent || {};
ToolKit.getVersionChangesByComponent = (toolkit, id) => toolkit?.data?.changesByComponent?.[id] || [];

ToolKit.resetVersionChanges = (toolkit) => {
  toolkit.dispatch({
    type: 'RESET_PROPERTY',
    property: 'changesByComponent',
  });
};

ToolKit.CACHE_KEYS = {
  IMAGES: 'images',
  KEYBOARD: 'keyboard',
  SLATE: 'slate',
};

ToolKit.scrollTo = (toolkit, id) => {
  toolkit.dispatch({
    type: 'SET_PROPERTY',
    property: 'scrollTo',
    payload: { id, updatedAt: Date.now() },
  });
};

ToolKit.getScrollTo = (toolkit) => toolkit?.data?.scrollTo;

ToolKit.getUsers = (toolkit) => toolkit?.data?.users || {};

ToolKit.setUser = (toolkit, user, blockId) => {
  toolkit.dispatch({
    type: 'UPDATE_PROPERTY',
    property: 'users',
    payload: { [user?.ID]: { data: user, blockId } },
  });
};

ToolKit.removeUser = (toolkit, user) => {
  const users = ToolKit.getUsers(toolkit);
  delete users[user?.ID];
  toolkit.dispatch({
    type: 'SET_PROPERTY',
    property: 'users',
    payload: users,
  });
};

ToolKit.isReadOnly = (toolkit) => toolkit?.status === ToolKit.STATUS.READ_ONLY;
ToolKit.isStatus = (toolkit, status) => toolkit?.status === status;

ToolKit.STATUS = {
  READ_ONLY: 'read-only',
  READ_ONLY_CONTENT: 'read-only-content',
  ADMIN: 'admin',
};
