import { EngagementSnapshots } from './EngagementSnapshots';
import { PageFlowChart } from './PageFlowChart';
import { PageViewsChart } from './PageViewsChart';

export const EngagementTab = () => {
  return (
    <>
      <EngagementSnapshots />
      <PageViewsChart />
      <PageFlowChart />
    </>
  );
};
