import { memo, useMemo, useRef } from 'react';
import { Box } from '../../shared/components';
import { Segment } from '../providers/segment/Segment';
import { useSegment } from '../providers/segment/use-segment';
import { Column } from './Column';
import { useDroppable } from '../utility/useDroppable';
import { Droppable } from '../utility/Droppable';
import { useToolKit } from '../providers/tool-kit/use-tool-kit';
import { ToolKit } from '../providers/tool-kit/ToolKit';
import { DROPPABLE_BLOCK_TYPES } from './block-helpers';
import { DragHandle } from '../utility/settings/DragHandle';
import { DROP_MOVE_ROW } from '../utility/constants';

export const DROPPABLE_ROW_TYPES = [...DROPPABLE_BLOCK_TYPES, DROP_MOVE_ROW];

export const Row = memo(() => {
  const rowRef = useRef();
  const segment = useSegment();
  const toolkit = useToolKit();
  const drag = ToolKit.getDrag(toolkit);
  const viewMode = ToolKit.getViewMode(toolkit);

  const properties = Segment.getProperties(segment);
  const hasMultiSelection = ToolKit.hasMultiSelection(toolkit);
  const isSectionGrouped = Segment.getIsSectionGrouped(segment);

  const childCount = Segment.getChildCount(segment);
  const activeRects = useMemo(
    () => ({
      top: childCount === 1 ? [0, -5, 100, 50] : [0, -5, 100, 10],
      bottom: childCount === 1 ? [0, 50, 100, 105] : [0, 90, 100, 105],
    }),
    [childCount]
  );

  const isDropEnabled = drag?.isDragging && DROPPABLE_ROW_TYPES.includes(drag?.type) && isSectionGrouped;
  const droppable = useDroppable({ disabled: !isDropEnabled, activeRects });

  const onDrag = (event) => {
    event.preventDefault();
    event.stopPropagation();
    ToolKit.startDrag(toolkit, {
      sourceId: segment.id,
      type: DROP_MOVE_ROW,
      toolType: 'row',
    });
  };

  const stack = viewMode === 'mobile' && properties?.stackColumns;

  return (
    <Box
      css={`
        position: relative;
        width: 100%;
        :not(:hover) {
          .row-overlay {
            * {
              display: none;
            }
          }
        }
      `}
      className="section-styles"
      flex={stack ? 'column' : 'left stretch'}
      ref={rowRef}
      data-scope="row"
      {...droppable.attributes}
    >
      {hasMultiSelection || ToolKit.isStatus(toolkit, ToolKit.STATUS.READ_ONLY) ? null : <RowOverlay onDrag={onDrag} />}
      {segment?.data?.children?.map((child, index) => (
        <Segment key={child?.id} scope="row" index={index} element={rowRef} {...child}>
          <Column stack={stack} />
        </Segment>
      ))}
      <Droppable {...droppable} />
    </Box>
  );
});

export const RowOverlay = ({ onDrag }) => {
  return (
    <Box
      flex="center"
      className="row-overlay"
      css={`
        position: absolute;
        top: 0;
        bottom: 0;
        right: -48px;
        left: -48px;
        margin: 16px;
        .drag-handle {
          margin-left: auto;
        }
      `}
    >
      <DragHandle label="Move Row" onDrag={onDrag} />
    </Box>
  );
};
