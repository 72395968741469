import { get, merge, set } from 'lodash';
import React, { useReducer } from 'react';

export const initialState = {
  companies: [],
  selectedBusiness: {},
  selectedAcl: {},
  parentAcl: {},
  parentBusiness: {},
  canSurveyUser: false,
  isBriteEmployee: null,
  user: {},
  acls: {},
  captured: [],
  kolla: {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'RESET': {
      return initialState;
    }

    case 'UPDATE': {
      return {
        ...state,
        [action.key]: {
          ...((action.key in state && state[action.key]) || {}),
          ...action.payload,
        },
      };
    }

    case 'SET': {
      return {
        ...state,
        [action.key]: action.payload,
      };
    }

    case 'SET-PROPERTIES': {
      return {
        ...state,
        ...action.payload,
      };
    }

    case 'SET-LAYOUT-ITEM': {
      let layout = { ...state.layout };
      const item = get(layout, action.path);
      const nextItem = merge(item, action.payload);
      set(layout, action.path, nextItem);
      return { ...state, layout };
    }

    default:
      return state;
  }
};

export const StoreContext = React.createContext();

export const StoreProvider = (props) => {
  const [store, dispatchStore] = useReducer(reducer, initialState);
  return <StoreContext.Provider value={{ store, dispatchStore }}>{props.children}</StoreContext.Provider>;
};
