import { CustomAxios } from '../../redux/axios/axios';

export const getEngagementSnapshots = async ({ dashboard, range }) => {
  const guideIDs = dashboard?.config?.guides?.map(({ ID }) => ID);
  const { startDate, endDate } = range;

  const { data } = await CustomAxios.post('/v2/analytics/pageAggregate', { guideIDs, startDate, endDate });
  return data;
};

export const getPageViews = async ({ guide, range }) => {
  const guideIDs = [guide.value];
  const { startDate, endDate } = range;

  const { data } = await CustomAxios.post('/v2/analytics/pageUserViews', {
    guideIDs,
    startDate,
    endDate,
  });
  return data;
};

export const getPageFlow = async ({ guide, range }) => {
  const guideID = guide.value;
  const { startDate, endDate } = range;

  const { data } = await CustomAxios.post('/v2/analytics/userPathingByGuide', {
    guideID,
    startDate,
    endDate,
  });
  return data;
};
