import { geoAlbersUsa } from 'd3-geo';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { ComposableMap, Geographies, Geography, Marker, useZoomPanContext, ZoomableGroup } from 'react-simple-maps';
import { ParentSize } from '@visx/responsive';
import { Box, Text } from '../../../shared/components';
import { colors } from '../../../shared/styles';
import { getTrafficByLocation } from '../../api/traffic';
import { useDashboard } from '../../context/DashboardContext';
import { ChartContainer } from '../chart/ChartContainer';
import { ChartControlDropdown } from '../chart/ChartControlDropdown';
import { ChartTooltip } from '../chart/ChartTooltip';

export const TrafficByLocationChart = () => {
  // Routing
  const { businessId } = useParams();

  // Context
  const { range, GUIDE_OPTIONS } = useDashboard();

  // State
  const [guide, setGuide] = useState(GUIDE_OPTIONS[0]);
  const [isResizing, setIsResizing] = useState(false);

  // Queries
  const { data, isLoading } = useQuery(['trafficByLocation', businessId, guide, range], () =>
    getTrafficByLocation({ guide, range })
  );

  useEffect(() => {
    // Timeout ID to manage debouncing of the zoom state reset.
    let resizeTimeout;

    // Event handler for detecting window resize.
    const handleResize = () => {
      // Set zooming state to true and debounce the reset.
      setIsResizing(true);
      if (resizeTimeout) clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(() => setIsResizing(false), 500); // Reset after 500ms of inactivity.
    };

    // Attach the event listener to the window object.
    window.addEventListener('resize', handleResize);

    // Cleanup function to remove the event listener and clear timeout.
    return () => {
      if (resizeTimeout) clearTimeout(resizeTimeout);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <ChartContainer
      isLoading={isLoading || isResizing}
      isEmpty={!data?.ViewsByLocation}
      title="Traffic by Location"
      description="Shows the volume of visitors by city for each guide associated with this dashboard. Bubble size is determined by the count of visitors."
      chartControls={
        <ChartControlDropdown label={guide.label} options={GUIDE_OPTIONS} onClick={(option) => setGuide(option)} />
      }
    >
      <Box
        css={`
          padding-top: 16px;
          height: 100vh;
          width: 100%;
        `}
      >
        <ParentSize>
          {({ width, height }) => (
            <ComposableMap
              width={width}
              height={height}
              projection="geoAlbersUsa"
              projectionConfig={{
                scale: width,
                translate: [width / 2, height / 2], // Center the map horizontally and vertically.
              }}
            >
              <ZoomableGroup
                minZoom={1}
                maxZoom={10}
                translateExtent={[
                  [-100, -50], // Adjusted minimum x, y to reduce whitespace.
                  [width + 100, height + 50], // Adjusted maximum x, y to reduce whitespace.
                ]}
              >
                <ZoomableMap data={data} />
              </ZoomableGroup>
            </ComposableMap>
          )}
        </ParentSize>
      </Box>
    </ChartContainer>
  );
};

const ZoomableMap = ({ data }) => {
  // Zoom and pan context.
  const { k } = useZoomPanContext();

  // Ref to track the last zoom level.
  const lastZoom = useRef(k);

  // State to track if the user has zoomed.
  const [hasZoomed, setHasZoomed] = useState(false);

  useEffect(() => {
    // Timeout ID for debouncing zoom state updates.
    let zoomTimeout;

    // Check if the zoom level has changed.
    if (k !== lastZoom.current) {
      // Clear any existing timeout to debounce.
      if (zoomTimeout) clearTimeout(zoomTimeout);

      // Update the zoom state after a delay.
      zoomTimeout = setTimeout(() => {
        lastZoom.current = k; // Update the last zoom level.
        setHasZoomed(true); // Mark that the user has zoomed.
      }, 50); // Debounce delay of 50ms.
    }

    // Cleanup the timeout on component unmount or dependency change.
    return () => {
      if (zoomTimeout) clearTimeout(zoomTimeout);
    };
  }, [k]);

  // Function to calculate the radius of the marker dots.
  const getDotRadius = useCallback(
    ({ Locations, Count }) => {
      if (!Locations?.length) return 0; // Return 0 if no locations are provided.

      // Extract the minimum and maximum visitor counts.
      const minCount = Locations[Locations.length - 1]?.Count || 0;
      const maxCount = Locations[0]?.Count || 0;

      // Handle edge case where all counts are the same.
      if (maxCount === minCount) return 5;

      // Compute a normalized scale for the count using a logarithmic scale.
      const normalizedScale =
        (Math.log(Count + 1) - Math.log(minCount + 1)) / (Math.log(maxCount + 1) - Math.log(minCount + 1));

      // Reset the zoomed state if the user has zoomed.
      if (hasZoomed) setHasZoomed(false);

      // Cap the zoom factor to avoid excessive scaling.
      const zoomFactor = Math.min(1.2, k);

      // Calculate the radius, scaling it based on the zoom level and normalized scale.
      return ((5 + normalizedScale * 15) / k) * zoomFactor;
    },
    [hasZoomed, k]
  );

  const geoUrl = 'https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json';

  return (
    <>
      <Geographies geography={geoUrl}>
        {({ geographies }) =>
          geographies.map((geo) => {
            return (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                fill={colors.gray[200]}
                stroke={colors.gray[300]}
                tabIndex={-1}
                style={{
                  default: { outline: 'none' },
                  hover: { outline: 'none' },
                  pressed: { outline: 'none' },
                }}
              />
            );
          })
        }
      </Geographies>
      {/* Render markers for each location. */}
      {data?.ViewsByLocation.flatMap(({ GuideID, Locations }) =>
        Locations?.filter(({ Lat, Long }) => {
          // Filter out invalid coordinates.
          const projection = geoAlbersUsa();
          return projection([Number(Long), Number(Lat)]) !== null;
        }).map(({ Lat, Long, CityName, Count }, index) => {
          // Calculate the radius for the marker.
          const radius = getDotRadius({ Locations, Count });
          return (
            <ChartTooltip
              key={`${GuideID} + ${index}`}
              label={CityName}
              description={`Visitors: ${Count}`}
              renderTooltip={() => (
                <Box
                  css={`
                    display: flex;
                    flex-direction: column;
                  `}
                >
                  <Text caption bold color={colors.black}>
                    {CityName}
                  </Text>
                  <Box
                    css={`
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                      gap: 4px;
                    `}
                  >
                    <Text caption color={colors.gray[500]}>
                      Visitors:
                    </Text>
                    <Text caption bold color={colors.lite.purple}>
                      {Count}
                    </Text>
                  </Box>
                </Box>
              )}
            >
              <Marker coordinates={[Number(Long), Number(Lat)]}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={radius * 2}
                  height={radius * 2}
                  viewBox={`0 0 ${radius * 2} ${radius * 2}`}
                  fill="none"
                >
                  <ellipse opacity="0.8" cx={radius} cy={radius} rx={radius} ry={radius} fill={colors.lite.purple} />
                </svg>
              </Marker>
            </ChartTooltip>
          );
        })
      )}
    </>
  );
};
