import {
  DROP_CREATE_BLOCK,
  DROP_CREATE_SECTION,
  DROP_MOVE_BLOCK,
  DROP_MOVE_ROW,
  DROP_MOVE_SECTION,
} from '../../utility/constants';
import { attachAction, getAction, removeEmptyActions, updateAction } from './composition/action-utils';
import { createBlock, createBlockEmpty, moveBlock } from './composition/block-utils';
import { compileCompositionActions, getComposition, removeFromComposition } from './composition/composition-utils';
import { moveRow } from './composition/row-utils';
import { createSection, moveSection } from './composition/section-utils';
import { Content } from './Content';

// Functions and values found here are used by
// the methods in the Content.jsx file.

export const getUpdateAction = (content, id, updateFn) => {
  let unit = {};
  let property = 'data';
  if (content?.data?.[id]) {
    unit = content.data[id];
  } else if (content?.sections) {
    unit = content.sections.find((section) => section.id === id);
    property = 'sections';
  }
  const updates = updateFn(unit);
  return {
    id,
    updates,
    property,
    old: unit,
    type: 'update',
    createdAt: Date.now(),
  };
};

const getDragActions = (composition, drag) => {
  const source = getAction(composition, drag.sourceId);
  attachAction(composition, source);
  const destination = getAction(composition, drag.destinationId);
  attachAction(composition, destination);
  return { source, destination };
};

export const getDropActions = (content, drag) => {
  const composition = getComposition(content);
  if (drag?.type === DROP_CREATE_BLOCK) {
    if (!drag?.destinationId) {
      return createBlockEmpty({ composition, drag });
    } else {
      return createBlock({ composition, drag });
    }
  } else if (drag?.type === DROP_MOVE_BLOCK) {
    const dragActions = getDragActions(composition, drag);
    return moveBlock({ composition, edge: drag?.edge, ...dragActions });
  } else if (drag?.type === DROP_MOVE_ROW) {
    const dragActions = getDragActions(composition, drag);
    return moveRow({ composition, edge: drag?.edge, ...dragActions });
  } else if (drag?.type === DROP_CREATE_SECTION) {
    return createSection({ composition, drag });
  } else if (drag?.type === DROP_MOVE_SECTION) {
    const dragActions = getDragActions(composition, drag);
    return moveSection({ composition, edge: drag?.edge, ...dragActions });
  }
  return [];
};

const extractChildren = (composition, action) => {
  let list = [];
  const children = action?.data?.children || [];
  children.forEach((child) => {
    list.push(child.id);
    const action = getAction(composition, child.id);
    attachAction(composition, action);
    if (action?.data?.children?.length) {
      const children = extractChildren(composition, action);
      list = [...list, ...children];
    }
  });
  return list;
};

export const removeComposition = (composition, id) => {
  const action = getAction(composition, id);
  attachAction(composition, action);

  if (!!action?.data?.children?.length) {
    const ids = extractChildren(composition, action);
    for (const id of ids) {
      const action = composition[id];
      removeFromComposition(composition, action);
      updateAction(composition, id, 'remove', {});
    }
  } else {
    removeFromComposition(composition, action);
    updateAction(composition, action.id, 'remove', {});
  }
};

export const getRemoveSelectionActions = (content, id) => {
  const composition = getComposition(content);
  removeComposition(composition, id);
  removeEmptyActions(composition);
  const actions = compileCompositionActions(composition);
  return actions;
};

export const getBlockIndices = (content, block) => {
  let list = [];
  if (block?.parentId) {
    const parent = Content.get(content, block?.parentId);
    const index = parent?.children?.findIndex((child) => child?.id === block?.id) ?? 0;
    const next = getBlockIndices(content, parent);
    list = [...next, index];
    if (parent?.type === 'section') {
      const index = content?.sections?.findIndex((section) => section?.id === parent?.id) ?? 0;
      list = [index, ...list];
    }
  }
  return list;
};

export const getWeightedAggregateIndices = (list) => {
  let positionValue = 0;
  for (let i = 0; i < list.length; i++) {
    const length = (list.length + 1 - i) * 100;
    let weight = (1 / (i + 1)) * length; // Higher weight for lower indices
    let val1 = list[i]; // Always take from arr1
    positionValue += val1 * weight;
  }
  return positionValue;
};

export const getCopiableData = (blockToCopy, metadata, stringify = true) => {
  const object = { metadata, data: blockToCopy };
  return stringify ? JSON.stringify(object) : object;
};

const pasteBlock = (content, block, id) => {
  let destinationId = id;
  const parent = Content.getParent(content, destinationId);
  if (parent?.type === 'column') {
    const grandparent = Content.getParent(content, parent?.id);
    if (grandparent?.children?.length === 1) {
      destinationId = grandparent?.id;
    }
  }
  return {
    pasteType: block?.metadata?.selection,
    data: {
      type: DROP_CREATE_BLOCK,
      edge: 'bottom',
      sourceId: '',
      destinationId,
      toolType: block?.data?.type,
      copiedData: block?.data,
    },
  };
};

export const getPaste = (content, block, id) => {
  if (block?.metadata?.selection === 'single') {
    return pasteBlock(content, block, id);
  } else {
    return {
      pasteType: block?.metadata?.selection,
      data: block?.data,
    };
  }
};
