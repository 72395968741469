import { Box, Loader, Text } from '../../../shared/components';
import { colors } from '../../../shared/styles';

/**
 * ChartContainer is a styled container for a chart. It includes a title, description, and optional chart controls.
 *
 * @param {boolean} isLoading: Whether the chart is loading.
 * @param {boolean} isEmpty: Whether the chart has no data to display.
 * @param {string} title: The title of the chart.
 * @param {string} description: A brief description of the chart.
 * @param {string} chartHeight: The height of the chart. Defaults to 400px.
 * @param {ReactNode} chartControls: Optional controls for the chart, such as buttons or dropdowns. This can be any valid React node.
 * @param {ReactNode} children: The chart content to be displayed within the container. This can be any valid React node.
 * @returns {ReactNode} A styled container for a chart.
 */
export const ChartContainer = ({
  isLoading = false,
  isEmpty = false,
  title = 'Untitled Chart',
  description = 'No description provided.',
  chartHeight = 400,
  chartControls,
  children,
}) => {
  return (
    <Box
      css={`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 16px 24px 24px;
        border-radius: 16px;
        border: 1px solid ${colors.gray[300]};
        gap: 8px;
      `}
    >
      <Box
        css={`
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding-top: 8px;
          padding-bottom: ${chartControls ? '0' : '16px'};
        `}
      >
        <Text h2>{title}</Text>
        {chartControls}
      </Box>
      <Text body>{description}</Text>
      {isLoading ? (
        <Box
          css={`
            height: ${chartHeight}px;
            width: 100%;
          `}
        >
          <Loader />
        </Box>
      ) : isEmpty ? (
        <ChartContainer.EmptyState height={chartHeight} />
      ) : (
        children
      )}
    </Box>
  );
};

ChartContainer.EmptyState = ({ width = '100%', height = 400 }) => {
  return (
    <Box
      css={`
        display: flex;
        justify-content: center;
        align-items: center;
        width: ${width};
        height: ${height}px;
      `}
    >
      <Text>No data available for the selected guide.</Text>
    </Box>
  );
};
