import { interpolateWarm } from 'd3-scale-chromatic';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';
import { ParentSize } from '@visx/responsive';
import { scaleOrdinal } from '@visx/scale';
import { AnimatedAxis, AnimatedBarSeries, AnimatedBarStack, AnimatedGrid, Tooltip, XYChart } from '@visx/xychart';
import { Box, Text } from '../../../shared/components';
import { colors } from '../../../shared/styles';
import { getPageViews } from '../../api/engagement';
import { useDashboard } from '../../context/DashboardContext';
import { mapPageViewsData } from '../../utils/engagement';
import { ChartContainer } from '../chart/ChartContainer';
import { ChartControlDropdown } from '../chart/ChartControlDropdown';
import { ChartTable } from '../chart/ChartTable';

export const PageViewsChart = () => {
  // Routing
  const { businessId } = useParams();

  // Context
  const { range, GUIDE_OPTIONS } = useDashboard();

  // State
  const [guide, setGuide] = useState(GUIDE_OPTIONS[0]);

  // Queries
  const { data, isLoading } = useQuery(['pageViews', businessId, guide, range], () => getPageViews({ guide, range }));

  // Memos
  const pageViewsData = useMemo(() => {
    if (isLoading || !data?.GuidePages) return [];
    return mapPageViewsData({ data });
  }, [data]);

  const colorScale = useMemo(() => {
    if (pageViewsData?.length === 0) return scaleOrdinal();
    const PageNames = pageViewsData.map((page) => page.PageDetails.PageName);
    return scaleOrdinal({
      domain: PageNames,
      range: PageNames.map((_, index) => interpolateWarm(0.8 - index / PageNames.length)),
    });
  }, [pageViewsData]);

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor('PageDetails', {
      header: () => <ChartTable.ColumnHeader label="Page Name" />,
      cell: (info) => <ChartTable.ColumnCell label={`${info.getValue().PageNumber}. ${info.getValue().PageName}`} />,
    }),
    columnHelper.accessor('Visitors', {
      header: () => <ChartTable.ColumnHeader label="Visitors" />,
      cell: (info) => <ChartTable.ColumnCell label={info.getValue()} />,
    }),
    columnHelper.accessor('AverageTimeOnPage', {
      header: () => <ChartTable.ColumnHeader label="Avg. Time on Page" />,
      cell: (info) => <ChartTable.ColumnCell label={info.getValue()} />,
    }),
    columnHelper.accessor('ViewedEntirePage', {
      header: () => <ChartTable.ColumnHeader label="Viewed Entire Page" />,
      cell: (info) => (
        <ChartTable.ColumnHelperCell
          label={info.getValue()?.Count || 0}
          helper={`(${info.getValue()?.Percent || 0}%)`}
        />
      ),
    }),
    columnHelper.accessor('AveragePageViewedPercentage', {
      header: () => <ChartTable.ColumnHeader label="Avg. Percentage of Page Viewed" />,
      cell: (info) => <ChartTable.ColumnCell label={`${Math.ceil(info.getValue())}%`} />,
    }),
  ];

  const chartHeight = 400;

  return (
    <ChartContainer
      isLoading={isLoading}
      isEmpty={pageViewsData.length === 0}
      title="Page Views"
      description="Shows how many visitors viewed each page this guide. Select a page in the table to see more information."
      chartControls={
        <ChartControlDropdown label={guide.label} options={GUIDE_OPTIONS} onClick={(option) => setGuide(option)} />
      }
    >
      <ParentSize>
        {({ width }) =>
          width > 0 && (
            <XYChart width={width} height={chartHeight} xScale={{ type: 'band' }} yScale={{ type: 'linear' }}>
              <AnimatedAxis orientation="left" animationTrajectory="min" />
              <AnimatedAxis orientation="bottom" animationTrajectory="max" />
              <AnimatedGrid columns numTicks={5} />
              <AnimatedBarStack order="descending">
                <AnimatedBarSeries
                  dataKey="Views"
                  data={pageViewsData}
                  xAccessor={(d) => d.PageDetails.PageNumber}
                  yAccessor={(d) => d.Views}
                  colorAccessor={(d) => colorScale(d.PageDetails.PageName)}
                />
              </AnimatedBarStack>
              <Tooltip
                snapTooltipToDatumX
                snapTooltipToDatumY
                showHorizontalCrosshair
                showVerticalCrosshair
                renderTooltip={({ tooltipData }) => {
                  const { datum, key } = tooltipData?.nearestDatum;
                  return (
                    <Box
                      css={`
                        display: flex;
                        flex-direction: column;
                        gap: 4px;
                      `}
                    >
                      <Text helper color={colors.gray[500]}>
                        {`${datum?.PageDetails?.PageNumber}. `}
                        {datum?.PageDetails?.PageName}
                      </Text>

                      <Box
                        css={`
                          display: flex;
                          flex-direction: row;
                          gap: 4px;
                        `}
                      >
                        <Text
                          helper
                          bold
                          color={colors.black}
                          css={`
                            text-transform: capitalize;
                          `}
                        >
                          {key}
                        </Text>
                        <Text helper bold color={colorScale(datum?.PageDetails?.PageName)}>
                          {datum[key]}
                        </Text>
                      </Box>
                    </Box>
                  );
                }}
              />
            </XYChart>
          )
        }
      </ParentSize>
      <ChartTable data={pageViewsData} columns={columns} />
    </ChartContainer>
  );
};
